<template>
<div>
    <employment-contract-list></employment-contract-list>
</div>
</template>

    
<script>
export default {}
</script>
